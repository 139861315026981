<div class="layout-wrapper" [ngClass]="containerClass">
  <app-topbar></app-topbar>
  <div class="layout-sidebar rounded-sm border-solid">
      <!-- <app-menu [active]="menuActive" (menuItemRedirected)="hideMenu()" ></app-menu> -->
      <app-sidebar></app-sidebar>
  </div>
  <div class="layout-main-container">
      <div class="layout-main" id="content">
          <app-dashboard></app-dashboard>
        
      </div>
      <app-scroll-top></app-scroll-top>
      <app-footer></app-footer>
  </div>
  <!-- <app-config></app-config> -->
  <div class="layout-mask"></div>
</div>
