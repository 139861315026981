import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { MailObject } from '../dashboard/dashboard.component';
import { MailData } from '../layout/service/app.layout.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  private settings: any;
  private http=inject(HttpClient);
  constructor(httpHandler: HttpBackend) {
   //this.http = new HttpClient(httpHandler);
  }

  Init2() {
    return new Promise<void>((resolve, reject) => {
      console.log('AppInitService.init() called');
      ////do your initialisation stuff here
      setTimeout(() => {
        console.log('AppInitService Finished');
        resolve();
      }, 6000);
    });
  }

  Init(endpoint: string) {
    return new Promise<boolean>((resolve, reject) => {
      this.http.get(endpoint).subscribe(
        (value) => {
          this.settings = value;
          resolve(this.settings);
        },
        (error) => {
          console.log(error)
          reject(error);
        }
      );
    });
  }

  getSettings(key?: string | Array<string>): any {
    if (!key || (Array.isArray(key) && !key[0])) {
      return this.settings;
    }

    if (!Array.isArray(key)) {
      key = key.split('.');
    }

    let result = key.reduce(
      (acc: any, current: string) => acc && acc[current],
      this.settings
    );

    return result;
  }
 
  SendStartMail(mailObject: MailObject,baseUrl:string) {
    let mailData: MailData = {
      ToAddress: [] = ["ferhatsengobul@hotmail.com"],
      CcAddress: [] = [],
      Subject: mailObject.subject,
      Body:mailObject.message,
      TemplateName: "default"
    };
    return this.http.post<boolean>(`${baseUrl}Mail/SendMail`, mailData);
  }
}
