import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, InjectionToken, NgModule, OnInit } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgOptimizedImage, CommonModule } from '@angular/common';
import { provideHttpClient,withFetch } from '@angular/common/http';

import { AppComponent } from './app.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AppTopBarComponent } from '../layout/app.topbar.component';
import { AppMenuComponent } from '../layout/app.menu.component';
import { AppFooterComponent } from '../layout/app.footer.component';
import { AppSidebarComponent } from '../layout/app.sidebar.component';
import { AppMenuitemComponent } from '../layout/app.menuitem.component';
import { AppConfigModule } from '../layout/config/config.module';
import { ScrollTopComponent } from '../layout/components/scroll-top/scroll-top.component';
import { PlayerComponent } from '../layout/components/player/player.component';
import { PlayerDetailsComponent } from '../layout/components/player/player-details/player-details.component';

import { CardModule } from 'primeng/card';
import { ButtonModule } from 'primeng/button';
import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuModule } from 'primeng/menu';
import { TabMenuModule } from 'primeng/tabmenu';
import { ConfigService } from '../services/config.service';
import {StyleClassModule} from 'primeng/styleclass';
import { BadgeModule } from 'primeng/badge';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SidebarModule } from 'primeng/sidebar';
import { CarouselModule } from 'primeng/carousel';
import { OverlayModule } from '@angular/cdk/overlay';
import { TagModule } from 'primeng/tag';
import { initFlowbite } from 'flowbite';
import { SearchArrayPipe } from '../shared/pipe/search-array.pipe';

const AUTH_CONFIG_URL_TOKEN = new InjectionToken<string>('AUTH_CONFIG_URL');
const configUrl = '../assets/config/website.json';

@NgModule({
  declarations: [
    AppComponent,
        DashboardComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppMenuComponent,
        AppSidebarComponent,
        ScrollTopComponent,
        PlayerComponent,
        PlayerDetailsComponent,
        SearchArrayPipe
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,    
    BrowserAnimationsModule,    
    ReactiveFormsModule,
    InputTextModule,
    SidebarModule,
    BadgeModule,
    RadioButtonModule,
    InputSwitchModule,
    RippleModule,    
    AppConfigModule,
    CardModule,
    MenuModule,
    ButtonModule,
    SplitButtonModule,
    StyleClassModule,
    TabMenuModule,
    NgOptimizedImage, 
    CommonModule, 
    OverlayModule,
    CarouselModule,
    TagModule
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  providers: [
    
    provideClientHydration(),
    provideHttpClient(withFetch()),
     ConfigService,
     { provide: AUTH_CONFIG_URL_TOKEN, useValue: configUrl },
    {
      provide: APP_INITIALIZER, useFactory: initializerFactory,
      deps: [ConfigService, AUTH_CONFIG_URL_TOKEN], multi: true
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule implements OnInit {
  ngOnInit(): void {
    initFlowbite();
  }
}

export function initializerFactory(env: ConfigService, configUrl: string): any {
  const promise = env.Init(configUrl).then((value) => {
    console.log(value);
  });
  return () => promise;
}

export function initializeApp1(appInitService: ConfigService) {
  return (): Promise<any> => { 
    return appInitService.Init2();
  }
}
