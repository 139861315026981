import { Component, OnInit } from '@angular/core';
import { Product } from '../layout/models/product';
import { ProductService } from '../layout/service/product.service';
import { Carousel } from "flowbite";
import type { CarouselItem, CarouselOptions, CarouselInterface } from "flowbite";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfigService } from '../services/config.service';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  products: Product[] | undefined;
  paket: Product[] | undefined;
  slogan: Product[] | undefined;
  responsiveOptions: any[] | undefined;
  mailForm:FormGroup;
  
  constructor(private productService: ProductService, private fb:FormBuilder, private configService:ConfigService) {}

  ngOnInit() {
    this.productService.getProducts().then((products) => {
      this.products = products.filter(x=>x.category=="MicrokodTanitim");
      this.paket = products.filter(x=>x.category=="MicrokodPaket");
      this.slogan = products.filter(x=>x.category=="MicrokodSlogan");
    });

    this.responsiveOptions = [
      {
        breakpoint: '1199px',
        numVisible: 1,
        numScroll: 1,
      },
      {
        breakpoint: '991px',
        numVisible: 2,
        numScroll: 1,
      },
      {
        breakpoint: '767px',
        numVisible: 1,
        numScroll: 1,
      },
    ];

    this.mailForm= this.fb.group({
      name:new FormControl(null,Validators.required),
      email:new FormControl(null,[Validators.required,Validators.email]),
      title:new FormControl(null,Validators.required),
      cell:new FormControl(null,[Validators.required,Validators.pattern('(([+][(]?[0-9]{1,3}[)]?)|([(]?[0-9]{4}[)]?))\s*[)]?[-\s\.]?[(]?[0-9]{1,3}[)]?([-\s\.]?[0-9]{3})([-\s\.]?[0-9]{3,4})')]),
      message:new FormControl(null,Validators.required),
      
    });
  }

  isRequiredField(field: string) {
    const form_field = this.mailForm.get(field);

    if (form_field.valid) {
    
      return false;
    }

    const validator = form_field.validator({} as AbstractControl);
    return validator ;
  }

  onSentMail(){
    let mail= new MailObject();
    mail.keyHolderMail=this.mailForm.controls['email'].value;
    mail.message=this.mailForm.controls['name'].value+" "+this.mailForm.controls['email'].value+" "+this.mailForm.controls['cell'].value+" /n"+this.mailForm.controls['message'].value;
    mail.subject=this.mailForm.controls['title'].value;
    this.configService.SendStartMail(mail,"https://portal.mikrokodturkiye.com.tr/api/").subscribe(
      x=>{
        this.mailForm.reset();
       // this.messageService.add({ severity: "info", summary: "Mesajınız iletilmiştir.", life: 5000 })
      }
    );

  }


 
}
export class MailObject {
  keyHolderMail:string;
  name:string;
  email:string;
  phone:string;
  subject:string;
  message:string;
}
