import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AppComponent } from './app.component';

const routes: Routes = [ {
  path: 'dashboard',
  component: DashboardComponent,
  //canActivate: [MsalGuard],
},
{
  path: '',
  redirectTo: '/dashboard',
  pathMatch: 'full',
  //canActivate: [MsalGuard]
},
{
  path: '**',
  component: AppComponent
}];

@NgModule({
  imports: [RouterModule.forRoot(routes,{ useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
