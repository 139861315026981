<div class="grid">
  <div class="card rounded-sm mt-3 border-solid">
    <div class="grid grid-nogutter surface-section text-800">
      <div class="col-12 md:col-12 p-6 text-center align-items-center">
        <section>
          <span class="block text-3xl font-bold mb-2"
            >Dijitalleşin, Fark Yaratın, Kazanın!</span
          >
          <div class="text-2xl text-primary font-bold mb-3">
            "İş Süreçlerinizi Optimize Edin, Verimliliği Yakalayın!
          </div>
          <p class="mt-0 mb-4 text-1xl line-height-3">
            Mikrokod’un sunduğu dijital çözümler sayesinde iş süreçlerinizi
            optimize ederek, hem zaman hem de maliyet tasarrufu sağlayabilir,
            böylece verimliliğinizi en üst düzeye çıkarabilirsiniz. Dijitalleşme
            ile işletmenizi daha hızlı, daha esnek ve daha karlı hale getirin!
          </p>
        </section>
      </div>
    </div>
  </div>
</div>

<div class="grid">
  <div class="card rounded-sm mt-3 border-solid">
    <span class="block text-3xl font-bold mb-1 text-center align-items-center"
      >Öne Çıkan Hizmetler</span
    >
    <div class="grid grid-cols-1 gap-4">
      <p-carousel
        [value]="products"
        [numVisible]="3"
        [numScroll]="3"
        [circular]="true"
        [responsiveOptions]="responsiveOptions"
        ngSkipHydration
      >
        <ng-template let-product pTemplate="item">
          <div class="border-1 surface-border card rounded-sm m-2 p-3">
            <div class="mb-12">
              <div class="relative mx-auto">
                <img
                  src="../assets/layout/images/{{ product.image }}"
                  [alt]="product.name"
                  class="w-full border-round"
                />
                <p-tag
                  [value]="product.inventoryStatus"
                  [severity]="INSTOCK"
                  class="absolute"
                  [ngStyle]="{ 'left.px': 5, 'top.px': 5 }"
                />
              </div>
            </div>
            <div class="mb-12 text-2xl text-primary font-bold">
              {{ product.name }}
            </div>
            <div class="mb-12 text-1xl line-height-3">
              {{ product.description }}
            </div>
            <!-- <div class="flex justify-content-between align-items-center">
              <div class="mt-0 font-semibold text-xl"></div>
              <span>
                <p-button icon="pi pi-bars" styleClass="ml-2" />
              </span>
            </div> -->
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
</div>
<div class="grid">
  <div class="card rounded-sm mt-3 border-solid">
    <div class="grid grid-nogutter surface-section text-800">
      <div class="col-12 md:col-12 p-6 text-center align-items-center">
        <section>
          <span class="block text-3xl font-bold mb-1"
            >Dijital Dönüşümde Gücünüz: Mikrokod!</span
          >
          <div class="text-2xl text-primary font-bold mb-3">
            "Modern iş dünyasında, verimlilik ve yenilikle öne çıkın.
          </div>
          <p class="mt-0 mb-4 text-1xl line-height-3">
            Mikrokod Türkiye olarak, işletmelerin dijital dönüşüm süreçlerini
            hızlandıran ve verimliliklerini artıran yenilikçi çözümler
            sunuyoruz. Yazılım geliştirme, danışmanlık ve teknik destek
            alanlarında sunduğumuz hizmetlerle, işletmenizin dijital dünyada
            güçlü ve rekabetçi olmasını sağlıyoruz. Dijitalleşme, iş
            süreçlerinizin daha verimli ve etkin yönetilmesini sağlar.
            Mikrokod’un uzman ekibi ile iş süreçlerinizi optimize edin ve
            dijital başarıya ulaşın.
          </p>
        </section>
      </div>
    </div>
  </div>
</div>
<div class="grid">
  <div class="card rounded-sm mt-3 border-solid">
    <span class="block text-3xl font-bold mb-1 text-center align-items-center"
      >İşletmeler için Entegre ve Güçlü Çözüm Paketi</span
    >
    <div class="grid grid-cols-1 gap-4">
      <p-carousel
        [value]="paket"
        [numVisible]="3"
        [numScroll]="3"
        [circular]="true"
        [responsiveOptions]="responsiveOptions"
        ngSkipHydration
      >
        <ng-template let-product pTemplate="item">
          <div class="border-1 surface-border card rounded-sm m-2 p-3">
            <div class="mb-12">
              <div class="relative mx-auto">
                <img
                  src="../assets/layout/images/{{ product.image }}"
                  [alt]="product.name"
                  class="w-full border-round"
                />
                <p-tag
                  [value]="product.inventoryStatus"
                  [severity]="INSTOCK"
                  class="absolute"
                  [ngStyle]="{ 'left.px': 5, 'top.px': 5 }"
                />
              </div>
            </div>
            <div class="mb-12 text-2xl text-primary font-bold">
              {{ product.name }}
            </div>
            <div class="mb-12 text-1xl line-height-3">
              {{ product.description }}
            </div>
            <!-- <div class="flex justify-content-between align-items-center">
              <div class="mt-0 font-semibold text-xl"></div>
              <span>
                <p-button icon="pi pi-bars" styleClass="ml-2" />
              </span>
            </div> -->
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
</div>

<div class="grid">
  <div class="card rounded-sm mt-3 border-solid">
    <div class="grid grid-nogutter surface-section text-800">
      <div class="col-12 md:col-12 p-6 text-center align-items-center">
        <section>
          <span class="block text-3xl font-bold mb-1"
            >Müşteri odaklı, Değer katan</span
          >
          <div class="text-2xl text-primary font-bold mb-3">
            "MikroKod Türkiye, teknoloji alanında müşterilerimize değer katmayı
            amaçlayan bir iş ortağıdır.
          </div>
          <p class="mt-0 mb-4 text-1xl line-height-3">
            Ekibimiz, yazılım geliştirme, veritabanı yönetimi, web tasarımı,
            bulut bilişim ve mobil uygulama geliştirme gibi çeşitli alanlarda
            uzmanlaşmış deneyimli profesyonellerden oluşmaktadır.
            Müşterilerimizin ihtiyaçlarına özelleştirilmiş çözümler sunmak için
            sürekli olarak sektördeki gelişmeleri takip ediyor ve en son
            teknolojik trendleri uygulamaya çalışıyoruz.
          </p>
          <div class="text-2xl text-primary font-bold mb-3">
            İşinizi Hızlandırmak, Kolaylaştırmak ve Karlılığınızı Artırmak İçin
            Bizimle Çalışmalısınız!
          </div>
          <p class="mt-0 mb-4 text-1xl line-height-3">
            Günümüz iş dünyasında, kurumsal operasyonların verimli, hızlı ve
            hatasız bir şekilde yönetilmesi, başarı için kritik bir faktördür.
            Özellikle ERP sistemleriyle entegre çalışan web yazılımları,
            işletmelerin bu hedeflere ulaşmasında önemli bir rol oynar. İş
            dünyası giderek rekabetçi hale gelirken, kurumsal operasyonlarınızın
            verimli, hızlı ve hatasız olması, başarı için kritik bir unsur
            haline geliyor.
          </p>
        </section>
      </div>
    </div>
  </div>
</div>
<div class="grid">
  <div class="card rounded-sm mt-3 border-solid">
    <div class="grid grid-cols-1 gap-4">
      <p-carousel
        [value]="slogan"
        [numVisible]="3"
        [numScroll]="3"
        [circular]="true"
        [responsiveOptions]="responsiveOptions"
        ngSkipHydration
      >
        <ng-template let-product pTemplate="item">
          <div class="border-1 surface-border card rounded-sm m-2 p-3">
            <div class="mb-12 text-2xl line-height-3">
                {{ product.description }}
              </div>
          </div>
        </ng-template>
      </p-carousel>
    </div>
  </div>
</div>

<div class="grid">
  <div class="card rounded-sm mt-3 border-solid">
    <div class="grid grid-nogutter surface-section text-800">
      <div class="col-12 md:col-12 p-6 text-center align-items-center">
        <section>
          <span class="block text-3xl font-bold mb-1"
            >Hızlı, sade ve karlı çözümleri sunuyoruz</span
          >
          <div class="text-2xl text-primary font-bold mb-3">
            "Firmamızın sunduğu yazılım çözümleri ile işbirliği yapmanız
            gerektiğine dair dört önemli neden:
          </div>
          <p class="mt-0 mb-4 text-1xl line-height-3">
            1. Uzmanlık ve Deneyim: ERP sistemleri ile entegre çalışan web
            yazılımları konusundaki uzmanlığımız, yılların deneyimi ile
            birleşiyor.SAP, SAP Business One, Netsis gibi önde gelen ERP
            sistemleriyle entegre çalışabilme yeteneğimiz, iş süreçlerinizi daha
            verimli hale getirir. İşletmenizin mevcut altyapısına sorunsuz bir
            şekilde entegre olur, operasyonlarınızı kolaylaştırır.
          </p>

          <p class="mt-0 mb-4 text-1xl line-height-3">
            2. Verimlilikte Artış ve Hatasız Operasyonlar: Geliştirdiğimiz web
            yazılımları, iş süreçlerinizi optimize eder ve verimliliğinizi
            artırır.
          </p>
          <p class="mt-0 mb-4 text-1xl line-height-3">
            3. Hızlı ve Sorunsuz Entegrasyon: Yazılımlarımız, işletmenizin
            mevcut altyapısına sorunsuz bir şekilde entegre olabilir. Hızlı
            entegrasyon sayesinde, operasyonlarınızda kesintisiz bir geçiş
            yaşarsınız.
          </p>
          <p class="mt-0 mb-4 text-1xl line-height-3">
            4. Maliyet Etkin Çözümler: Bizimle çalışmanın maliyeti, uzun vadeli
            tasarruflar ve yatırım getirisi ile dengelenir. Karlılığınızı
            artırmak ve iş süreçlerinizi optimize etmek adına bütçenizi etkili
            bir şekilde yönetebilirsiniz..
          </p>
        </section>
      </div>
    </div>
  </div>
</div>

<div class="grid">
  <div class="card rounded-sm mt-3 border-solid">
    <div
      class="grid grid-nogutter surface-section text-800 grid-cols-1 gap-4 p-6"
    >
      <!-- <form class="max-w-xl" [formGroup]="mailForm">
        <div class="mb-5">
          <label
            for="base-input"
            class="block mb-2 text-sm font-medium dark:text-white"
            >Firma / Ad Soyad
            <span *ngIf="isRequiredField('name')">*</span></label
          >
          <input
            type="text"
            id="base-input"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div class="mb-5">
          <label
            for="base-input"
            class="block mb-2 text-sm font-medium dark:text-white"
            >E-mail<span *ngIf="isRequiredField('email')">*</span></label
          >
          <input
            type="text"
            id="base-input"
            class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div class="mb-5">
          <label
            for="small-input"
            class="block mb-2 text-sm font-medium dark:text-white"
            >Telefon No
            <span *ngIf="isRequiredField('cell')">* (05555555555)</span></label
          >
          <input
            type="text"
            id="small-input"
            class="block w-full p-2 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 text-xs focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />
        </div>
        <div class="mb-5">
          <label
            for="message"
            class="block mb-2 text-sm font-medium dark:text-white"
            >Mesaj <span *ngIf="isRequiredField('title')">*</span></label
          >
          <textarea
            id="message"
            rows="4"
            class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder=""
          ></textarea>
        </div>
        <div class="mb-5">
          <button
            class="btn btn-primary py-3 px-5"
            (click)="onSentMail()"
            type="submit"
          >
            Gönder
          </button>
        </div>
      </form> -->

      <div class="rounded p-3">
        <span class="block text-3xl font-bold mb-1">Bize Ulaşın</span>
        <div class="text-2xl text-primary font-bold mb-3">
          <i class="pi pi-mobile"></i> 0 532 491 2000
        </div>

        <p class="mt-0 mb-4 text-1xl line-height-3">
          <i class="pi pi-envelope"></i> info&#64;miktokodturkiye.com.tr
        </p>
        <p class="mt-0 mb-4 text-1xl line-height-3">
          Sadık İleri Bulvarı No:50 D:11 Torbalı / İzmir
        </p>
      </div>
    </div>
  </div>
</div>
