<section *ngFor="let player of players">
  <img [attr.src]="'/assets/' + player.imageName + '.avif'" width="1040" height="760" />
  <h2>{{ player.name }}</h2>
  <dl>
    <div>
      <dt>
        GP
      </dt>
      <dd>
        {{ player.games | number }}
      </dd>
    </div>
    <div>
      <dt>
        PTS
      </dt>
      <dd>
        {{ player.points | number }}
      </dd>
    </div>
    <div>
      <dt>
        FG%
      </dt>
      <dd>
        {{ player.fieldGoalPercentage | percent:'.1' }}
      </dd>
    </div>
    <div>
      <dt>
        3P%
      </dt>
      <dd>
        {{ player.threePointPercentage | percent:'.1' }}
      </dd>
    </div>
  </dl>

  <button
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="detailsOpen = !detailsOpen"
    [class.open]="detailsOpen"
    title="Player Details">
    &#8942;
    <span class="cdk-visually-hidden">
      Player Details
    </span>
  </button>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayOpen]="detailsOpen"
    cdkConnectedOverlayWidth="60%"
    (overlayOutsideClick)="detailsOpen = false"
    (detach)="detailsOpen = false"
    [cdkConnectedOverlayPositions]="[
      {
        originX: 'start',
        originY: 'center',
        overlayX: 'end',
        overlayY: 'center',
      },
      {
        originX: 'start',
        originY: 'top',
        overlayX: 'end',
        overlayY: 'top',
      },
      {
        originX: 'start',
        originY: 'bottom',
        overlayX: 'end',
        overlayY: 'bottom'
      }
    ]"
    cdkConnectedOverlayPush="true"
    [cdkConnectedOverlayViewportMargin]="7"
    [cdkConnectedOverlayScrollStrategy]="scrollStrategy">
    <app-player-details [player]="player"></app-player-details>
  </ng-template>
</section>
