import { Overlay, OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input, HostBinding } from '@angular/core';
import { Player } from './players';
import { enterLeaveAnimation } from './animations';


@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css'],
  animations: [
    enterLeaveAnimation
  ],
 
})

export class PlayerComponent {
  players: Player[];
  
  @HostBinding('@enterLeaveAnimation') animate = true;
  protected detailsOpen = false;
  protected scrollStrategy = this.overlay.scrollStrategies.reposition();
  // protected scrollStrategy = this.overlay.scrollStrategies.block();
  // protected scrollStrategy = this.overlay.scrollStrategies.close();
  // protected scrollStrategy = this.overlay.scrollStrategies.noop();

  constructor(private overlay: Overlay) {}
}
