<div class="layout-topbar">
    <a class="layout-topbar-logo" routerLink="">
        <img src="assets/layout/images/{{layoutService.config().colorScheme === 'light' ? 'logo' : 'logo'}}.png"
            alt="logo">
       
        <span class="text-lg text-primary font-bold mb-1 text-center mt-2" *ngIf="!isactive">
            <span  class="block text-2xl font-bold" style="color: #fff !important;">Mikrokod</span>
            Yazılım ve Bilişim Sistemleri
        </span>
    </a>
    <button *ngIf="false" #menubutton class="layout-menu-button layout-topbar-button" (click)="layoutService.onMenuToggle()">
        <i class="pi pi-bars"></i>
    </button>
    <button #topbarmenubutton class="layout-topbar-menu-button layout-topbar-button"
        (click)="layoutService.showProfileSidebar()">
        <i class="pi pi-ellipsis-v"></i>
    </button>
   
    <div #topbarmenu class="layout-topbar-menu"
        [ngClass]="{'layout-topbar-menu-mobile-active': layoutService.state.profileSidebarVisible}">
        <!-- <button *ngIf="!isAuthenticated" class="p-link layout-topbar-button" (click)="login()">
            <i class="pi pi-user"></i>
            <span>Giriş</span>
        </button>
        <button *ngIf="!isAuthenticated" class="p-link layout-topbar-button" (click)="login()">
            <i class="pi pi-user"></i>
            <span>Giriş</span>
        </button> -->
       
    </div>
    
</div>  