import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';

import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  host: {
    '(window:resize)': 'onWindowResize($event)',
  }
})
export class AppTopBarComponent implements OnInit {
  items!: MenuItem[];
  isAuthenticated = false;
  user: any = {};
  userInfoReady: boolean = false;
  usermenu: MenuItem[] = [];

  @ViewChild('menubutton') menuButton!: ElementRef;

  @ViewChild('topbarmenubutton') topbarMenuButton!: ElementRef;

  @ViewChild('topbarmenu') menu!: ElementRef;

  @HostListener('window:resize', ['onWindowResize($event)'])
  isactive = false;

  constructor(
    private _sanitizer: DomSanitizer,
    public layoutService: LayoutService,
    private router: Router
  ) {}

  ngOnInit() {
   
    this.items = [
      { label: 'Router Link', icon: 'pi pi-home', route: '/tabmenu' },
      {
        label: 'Programmatic',
        icon: 'pi pi-palette',
        command: () => {
          this.router.navigate(['/theming']);
        },
      },
      { label: 'External', icon: 'pi pi-link', url: 'https://angular.io/' },
    ];
  }

  onWindowResize(event) {    
    this.isactive = event.target.innerWidth< 768;    
  }
}
