import {
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { Subscription, filter } from 'rxjs';
import { AppSidebarComponent } from '../layout/app.sidebar.component';
import { AppTopBarComponent } from '../layout/app.topbar.component';
import { LayoutService } from '../layout/service/app.layout.service';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from '../services/config.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  overlayMenuOpenSubscription: Subscription;

  menuOutsideClickListener: any;

  profileMenuOutsideClickListener: any;

  @ViewChild(AppSidebarComponent) appSidebar!: AppSidebarComponent;

  @ViewChild(AppTopBarComponent) appTopbar!: AppTopBarComponent;

  menuActive: boolean;

  init: any;
  appLoading: boolean = false;
  currentAppName: string = '';

  constructor(
    @Inject(DOCUMENT) private document: Document,
      private cdr: ChangeDetectorRef,
      private config: ConfigService,
      public layoutService: LayoutService,
      public renderer: Renderer2,
      public router: Router
  ) {
      this.overlayMenuOpenSubscription =
          this.layoutService.overlayOpen$.subscribe(() => {
              if (!this.menuOutsideClickListener) {
                  this.menuOutsideClickListener = this.renderer.listen(
                      'document',
                      'click',
                      (event) => {
                          const isOutsideClicked = !(
                              this.appSidebar.el.nativeElement.isSameNode(
                                  event.target
                              ) ||
                              this.appSidebar.el.nativeElement.contains(
                                  event.target
                              ) ||
                              this.appTopbar.menuButton.nativeElement.isSameNode(
                                  event.target
                              ) ||
                              this.appTopbar.menuButton.nativeElement.contains(
                                  event.target
                              )
                          );

                          if (isOutsideClicked) {
                              this.hideMenu();
                          }
                      }
                  );
              }

              if (!this.profileMenuOutsideClickListener) {
                  this.profileMenuOutsideClickListener = this.renderer.listen(
                      'document',
                      'click',
                      (event) => {
                          const isOutsideClicked = !(
                              this.appTopbar.menu.nativeElement.isSameNode(
                                  event.target
                              ) ||
                              this.appTopbar.menu.nativeElement.contains(
                                  event.target
                              ) ||
                              this.appTopbar.topbarMenuButton.nativeElement.isSameNode(
                                  event.target
                              ) ||
                              this.appTopbar.topbarMenuButton.nativeElement.contains(
                                  event.target
                              )
                          );

                          if (isOutsideClicked) {
                              this.hideProfileMenu();
                          }
                      }
                  );
              }

              if (this.layoutService.state.staticMenuMobileActive) {
                  this.blockBodyScroll();
              }
          });

      this.router.events
          .pipe(filter((event) => event instanceof NavigationEnd))
          .subscribe(() => {
              this.hideMenu();
              this.hideProfileMenu();
          });
  }

  ngAfterViewInit() {
     
      this.cdr.detectChanges();
  }


  ngOnInit() {
   //const configUrl = '../assets/config/website.json';
    
    // this.config.init(configUrl).then((value) => {
    //     console.log(value);
        
    //     });
     console.log(this.config.getSettings("baseUrl"))
    
      this.init = {
          initialRoute: 'dashboard',
          outletId: 'content',
          preload: true,
          clients: [
              {
                  loaded: false,
                  src: '/assets/micro-frontends/lift-app/main.js',
                  element: 'lift-app',
                  route: '/lift-app',
              },
          ],
      };

     
  }

  hideMenu() {
      this.layoutService.state.overlayMenuActive = false;
      this.layoutService.state.staticMenuMobileActive = false;
      this.layoutService.state.menuHoverActive = false;
      if (this.menuOutsideClickListener) {
          this.menuOutsideClickListener();
          this.menuOutsideClickListener = null;
      }
      this.unblockBodyScroll();
      this.menuActive = false;
  }

  hideProfileMenu() {
      this.layoutService.state.profileSidebarVisible = false;
      if (this.profileMenuOutsideClickListener) {
          this.profileMenuOutsideClickListener();
          this.profileMenuOutsideClickListener = null;
      }
  }

  blockBodyScroll(): void {
      if (this.document.body.classList) {
        this.document.body.classList.add('blocked-scroll');
      } else {
        this.document.body.className += ' blocked-scroll';
      }
  }

  unblockBodyScroll(): void {
      if (this.document.body.classList) {
        this.document.body.classList.remove('blocked-scroll');
      } else {
        this.document.body.className = document.body.className.replace(
              new RegExp(
                  '(^|\\b)' +
                      'blocked-scroll'.split(' ').join('|') +
                      '(\\b|$)',
                  'gi'
              ),
              ' '
          );
      }
  }

  get containerClass() {
      return {
          'layout-theme-light':
              this.layoutService.config().colorScheme === 'light',
          'layout-theme-dark':
              this.layoutService.config().colorScheme === 'dark',
          'layout-overlay':
              this.layoutService.config().menuMode === 'overlay',
          'layout-static': this.layoutService.config().menuMode === 'static',
          'layout-static-inactive':
              this.layoutService.state.staticMenuDesktopInactive &&
              this.layoutService.config().menuMode === 'static',
          'layout-overlay-active': this.layoutService.state.overlayMenuActive,
          'layout-mobile-active':
              this.layoutService.state.staticMenuMobileActive,
          'p-input-filled':
              this.layoutService.config().inputStyle === 'filled',
          'p-ripple-disabled': !this.layoutService.config().ripple,
      };
  }

  ngOnDestroy() {
      if (this.overlayMenuOpenSubscription) {
          this.overlayMenuOpenSubscription.unsubscribe();
      }

      if (this.menuOutsideClickListener) {
          this.menuOutsideClickListener();
      }
  }
}
