import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search-array'
})
export class SearchArrayPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!args) return value;
    return value.filter((v) => 
    v.binaAd.toLowerCase().indexOf(args.toLowerCase()) > -1 )
  }
  }

